import React, { useEffect, useState } from 'react';

const Message = ({ messages, successMessage }) => {

    const [showMessages, setShowMessages] = useState(true);

    useEffect(() => {
        if (messages?.length > 0 || successMessage) {
            setShowMessages(true);

            const timer = setTimeout(() => {
                setShowMessages(false);
            }, 55000); // Hide messages after 5 seconds

            // Cleanup the timer if the component unmounts before the timer ends
            return () => clearTimeout(timer);
        }
    }, [messages, successMessage]);

    if (!showMessages) return null; // Don't render the component if messages are hidden

    return (
        <>
            {messages && messages.length > 0 && (
                <div className="messages">
                    {messages.map((message, index) => (
                        <div key={index} className="message">
                            <span className="label label-danger">{message}</span>
                        </div>
                    ))}
                </div>
            )}
            
            {successMessage && <span className="label label-primary">{successMessage}</span>}
        </>
    );
}

export default Message;
