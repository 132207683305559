import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Table, Button, Modal, Form } from 'react-bootstrap';
import PageHeader from '../../../Layouts/PageHeader/PageHeader';
import AppService from '../../../services/AppService';

import { Link } from 'react-router-dom';
import Rodal from "rodal";
import 'rodal/lib/rodal.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import usePermissions from '../../../hooks/usePermissions';

const ServicesList = () => {
  const [services, setServices] = useState([]);
  const { hasPermission } = usePermissions(); // Use the custom hook 
  const [showModal, setShowModal] = useState(false);
  const [selectedList, setSelectedList] = useState(null);
  const [isEdit, setIsEdit] = useState(false); // Track if it's edit mode
  const [visible, setVisible] = useState(false);
  const [newItemData, setNewItemData] = useState({
    title: '',
    status: '',
    rank: '',
  });


  useEffect(() => {

    const fetchData = async () => {
      try {
        const data = await AppService.getAllServices();
        setServices(data);
      } catch (error) {
        toast.error(error);
      }

    };

    fetchData();
  }, []);



  //new 

  // Handle delete action
  const handleDelete = (uuid) => {
    setSelectedList(uuid);
    setVisible(true);
  };

  // Confirm delete
  const confirmDelete = async () => {
    try {
      await AppService.deleteService(selectedList);
      setServices(services.filter(row => row.uuid !== selectedList)); // Filter the deleted item
      setVisible(false);
      toast.success('Successfully Deleted');
    } catch (error) {
      toast.error(error.message);
    }
  };

  // Close Modal
  const handleModalClose = () => {
    setShowModal(false);
    setIsEdit(false); // Reset edit mode
    setNewItemData({
      name: '',
    });
  };

  // Show Modal for add/edit
  const handleModalShow = () => {
    setShowModal(true);
  };

  //Edit budget item
  const handleEdit = (personal) => {
    setSelectedList(personal.uuid);
    setNewItemData({
      ...personal,  // Populate the form with the existing budget data
      name: personal.name,
      status: personal.status,
      rank: personal.rank,
    });
    setIsEdit(true); // Set edit mode to true
    setShowModal(true);
  };

  // Handle form input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewItemData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle form submit (Add or Edit)
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (isEdit) {
        await AppService.updateService(selectedList, newItemData);
        setServices(services.map(b => (b.uuid === selectedList ? { ...b, ...newItemData } : b)));

        toast.success('Successfully updated');
      } else {
        const newItem = await AppService.createService(newItemData);
        setServices([...services, newItem.services]);
        toast.success('Successfully added');
      }
    } catch (error) {
      toast.error(error.message);
    }

    handleModalClose();
  };
  return (
    <div >
         <ToastContainer />
      <PageHeader titles="All  AppServices" active="List" items={['AppServices']} />

      {/* <!-- Row --> */}
      <Row>
        <Col xl={12}>
          <Card>
            <Card.Header className="bg-success text-white d-flex justify-content-between align-items-center">
              <Card.Title as='h3'>AppServices</Card.Title>
              {hasPermission('appservice-create') && (
                <Button variant="light" onClick={handleModalShow}>New AppServices</Button>
              )}
            </Card.Header>

            <Card.Body>
              {/* <p>Using the most basic table markup, here’s how <code className="highlighter-rouge">.table</code> based tables look in Bootstrap.</p> */}
              <div className="table-responsive">
                <Table className="text-nowrap text-md-nowrap mb-0">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Title</th>
                      <th>Status</th>
                      <th>Rank</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {services.map((row, index) => (
                      <tr key={row.uuid}>
                        <td>{index + 1}</td>
                        <td>{row.title}</td>
                        <td>{row.status}</td>
                        <td>{row.rank}</td>
                        <td >
                          {hasPermission('appservice-delete') && (
                            <Link
                              onClick={() => handleDelete(row.uuid)}
                              className="text-danger me-3 action-link"
                            >
                              <i className="ion ion-trash-a"></i> Delete
                            </Link>
                          )}

                          {hasPermission('appservice-edit') && (
                            <Link
                              onClick={() => handleEdit(row)}
                              className="text-info action-link"
                            >
                              <i className="ion ion-edit"></i> Edit
                            </Link>
                          )}

                        </td>

                      </tr>
                    ))}


                  </tbody>
                </Table>

              </div>
            </Card.Body>
          </Card>
        </Col>

      </Row>
      {/* <!-- End Row --> */}

      <Modal show={showModal} onHide={handleModalClose} centered>
        <Modal.Header closeButton className="bg-success text-white">
          <Modal.Title className="w-100 text-center">{isEdit ? 'Edit ' : 'Add '}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>

            <Form.Group controlId="formTitle" className="mb-3">
              <Form.Label>Title </Form.Label>
              <Form.Control
                type="text"
                name="title"
                value={newItemData.title}
                onChange={handleInputChange}
                placeholder="Enter"
                required
              />
            </Form.Group>

            <Form.Group controlId="formPrice" className="mb-3">
              <Form.Label>Status </Form.Label>
              <Form.Control
                type="number"
                name="status"
                value={newItemData.status}
                onChange={handleInputChange}
                placeholder="Enter "
                required
              />
            </Form.Group>
            <Form.Group controlId="formPrice" className="mb-3">
              <Form.Label>rank </Form.Label>
              <Form.Control
                type="number"
                name="rank"
                value={newItemData.rank}
                onChange={handleInputChange}
                placeholder="Enter "
                required
              />
            </Form.Group>


            <div className="d-flex justify-content-end">
              <Button variant="secondary" onClick={handleModalClose}>Close</Button>
              <Button variant="success" type="submit" className="px-4">
                {isEdit ? 'Update ' : 'Save Changes'}
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      <Rodal visible={visible} onClose={() => setVisible(false)} animation='zoom' height={170}>
        <div>
          <h4>Confirm Delete</h4>
          <p>Are you sure you want to delete this Data?</p>
          <Button variant="danger" className="me-2" onClick={confirmDelete}>Delete</Button>
          <Button variant="default" onClick={() => setVisible(false)}>Cancel</Button>
        </div>
      </Rodal>
    </div>
  )
};

export default ServicesList;