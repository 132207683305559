// src/hooks/usePermissions.js
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import RBAC from '../services/RBAC';

const usePermissions = () => {
  const [userPermissions, setUserPermissions] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {

        const isLocked = sessionStorage.getItem('tooManyRequests');
        if (isLocked) {
          toast.error('Too many requests. Please try again later.');
          return;
        }

        const result = await RBAC.userPermission();

        if (result.status === 429) {
          toast.error(result.message);
          // Lock further requests for 30 seconds
          sessionStorage.setItem('tooManyRequests', 'true');
          setTimeout(() => {
            sessionStorage.removeItem('tooManyRequests');
          }, 30000); // 30-second lock
          return;
        }

        if (!result.error) {
          setUserPermissions(result.message);
          setUserPermissions(Array.isArray(result) ? result : []);
        } else {
          toast.error('Error fetching user permissions');
        }


      }
      catch (error) {
        toast.error('Error fetching user permissions');
      }
    };

    fetchData();
  }, []);

  const hasPermission = (permission) => {
    return Array.isArray(userPermissions) && userPermissions.includes(permission);
  };

  return { userPermissions, hasPermission };


};

export default usePermissions;
