import React from "react";
import { useState,useEffect } from "react";
import { Row, Col, Card, Form, Button } from 'react-bootstrap';
import PageHeader from "../../../Layouts/PageHeader/PageHeader";
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import Message from "../../Message";
import AppService from '../../../services/AppService';
import { checkToken } from '../../AuthUtils'; 
import useRBAC from "../../../hooks/useRBAC";


const ServicesCreate = () => {
    const [validated, setValidated] = useState(false);
    const navigate = useNavigate();
    const [message, setMessage] = useState();
    const [errorMessage, setErrorMessage] = useState([]);
    const [newItemData, setNewItemData] = useState({});
    const userPermissions = useRBAC('appservice-create', '/appServices');

    // const [token, setToken] = useState('');
    useEffect(() => {
        checkToken();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewItemData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
        }
        setValidated(true);

        const newItem = await AppService.createService(newItemData);

        if (newItem.services) {

            setMessage('Successfully Added')
            setNewItemData({ ...newItemData, name: '',status: '',rank: '', }); // Reset name field to empty string
            setValidated(false);
            // console.log(newItem)
        }
        else {


            const myErrormessages = Object.values(newItem);
            setErrorMessage(myErrormessages);
        }

        navigate('/appServices/create');


    };

    return (

        <div >
            <PageHeader titles={<Link className="btn-pill btn btn-outline-success" to="/appServices">Go Back</Link>} active="Create" items={['Week']} />

            {/* <!-- ROW OPEN --> */}
            <Row>
                <Col lg={12} md={12}>
                    <Card>
                        <Card.Header>
                            <Card.Title as='h3'>New AppServices</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Message messages={errorMessage} successMessage={message} />
                            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                <div className="form-row">
                                    <Col xl={6} className="mb-3">
                                        <Form.Label>Title</Form.Label>
                                        <Form.Control name="title" required type="text" value={newItemData.title} onChange={handleInputChange} placeholder="Enter Title" />
                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    </Col>
                                </div>
                                <div className="form-row">
                                    <Col xl={6} className="mb-3">
                                        <Form.Label>Status</Form.Label>
                                        <Form.Control name="status" required type="number" value={newItemData.status} onChange={handleInputChange} placeholder="Enter Status" />
                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    </Col>
                                </div>
                                <div className="form-row">
                                    <Col xl={6} className="mb-3">
                                        <Form.Label>Rank</Form.Label>
                                        <Form.Control name="rank" required type="number" value={newItemData.rank} onChange={handleInputChange} placeholder="Enter Rank" />
                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    </Col>
                                </div>

                                <div role="group" aria-label="Basic example" class="file-attach m-2 btn-group">
                                    <button type="submit" class="btn-pill btn btn-info-light">  Save</button>  </div>

                            </Form>

                        </Card.Body>
                    </Card>
                </Col>



            </Row>
            {/* <!-- ROW CLOSED --> */}
        </div>
    )
};

export default ServicesCreate;