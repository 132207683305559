import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import RBAC from '../services/RBAC'; // Import your RBAC utility

const ProtectedRoute = ({ element: Element, permission, role = null }) => {
  const [userPermissions, setUserPermissions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const userRole = sessionStorage.getItem('role');

  useEffect(() => {
    RBAC.userPermission()
      .then((data) => {
        setUserPermissions(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching user permissions:', error.message);
        setError(error.message);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <div>Loading...</div>; // You can display a loader here
  }

  if (error) {
    return <div>Error: {error}</div>; // Display any error that occurred while fetching permissions
  }

  // Check if the user has the required permission and role
  const hasPermission = userPermissions.includes(permission);
  const hasRole = role ? userRole === role : true;

  // If user has both the required permission and role, render the component, otherwise redirect
  if (hasPermission && hasRole) {
    return <Element />;
  } else {
    return <Navigate to="/unauthorized" />;
  }
};

export default ProtectedRoute;
