import React, { useEffect } from "react";
import { useState } from "react";
import { Row, Col, Card, Form, Button } from 'react-bootstrap';
import PageHeader from "../../../Layouts/PageHeader/PageHeader";
import { Link } from 'react-router-dom';
import { useNavigate, useParams } from "react-router-dom";
import Message from "../../Message";
import AppService from '../../../services/AppService';
import { checkToken } from '../../AuthUtils';
import useRBAC from "../../../hooks/useRBAC";







const ServicesEdit = () => {
    const { uuid } = useParams();

    const [validated, setValidated] = useState(false);
    const navigate = useNavigate();
    const [message, setMessage] = useState();
    const [errorMessage, setErrorMessage] = useState([]);
    const [updatedData, setUpdatedData] = useState({
        // Initialize with default values or leave empty
        // month_day: "",
        // Add other fields as needed
    });
    const userPermissions = useRBAC('appservice-edit', '/appServices');

    // const [token, setToken] = useState(''); 
  useEffect(() => { 
    checkToken();
  }, []);




    // Basic Form validation


    useEffect(() => {
        // Fetch item details from the API when the component mounts
        const fetchItem = async () => {
            const data = await AppService.getServicesByUuid(uuid);
            //console.log(uuid);
            //  console.log(data);
            //  setItem(data);
            setUpdatedData(data);

        };


        fetchItem();
    }, [uuid]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUpdatedData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
        }
        setValidated(true);

        const updatedItem = await AppService.updateService(uuid, updatedData);
        const mymessages = Object.values(updatedItem);
        if (updatedItem.success) {

            setMessage(mymessages);
            // console.log(message)

        }
        else {
            const myErrormessages = Object.values(updatedItem);
            setErrorMessage(myErrormessages);
            // console.log(errorMessage)
        }

        navigate('/appServices/edit/' + uuid);


    };






    return (

        <div >
            <PageHeader titles={<Link className="btn-pill btn btn-outline-success" to="/appServices">Go Back</Link>} active="Create" items={['AppServices']} />

            {/* <!-- ROW OPEN --> */}
            <Row>
                <Col lg={12} md={12}>
                    <Card>
                        <Card.Header>
                            <Card.Title as='h3'>Edit AppServices </Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Message messages={errorMessage} successMessage={message} />
                            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                <div className="form-row">
                                    <Col xl={6} className="mb-3">
                                        <Form.Label>Title</Form.Label>
                                        <Form.Control name="title" required type="text" value={updatedData.title} onChange={handleInputChange} placeholder="Enter Title" />
                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    </Col>
                                </div>
                                <div className="form-row">
                                    <Col xl={6} className="mb-3">
                                        <Form.Label>Status</Form.Label>
                                        <Form.Control name="status" required type="number" value={updatedData.status} onChange={handleInputChange} placeholder="Enter Status" />
                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    </Col>
                                </div>
                                <div className="form-row">
                                    <Col xl={6} className="mb-3">
                                        <Form.Label>Rank</Form.Label>
                                        <Form.Control name="rank" required type="number" value={updatedData.rank} onChange={handleInputChange} placeholder="Enter rank" />
                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    </Col>
                                </div>

                                <button type="submit" class="btn-pill btn btn-info-light">  Save Changes</button>

                            </Form>

                        </Card.Body>
                    </Card>
                </Col>



            </Row>
            {/* <!-- ROW CLOSED --> */}
        </div>
    )
};

export default ServicesEdit;