import { API_URL, getToken, apiKey } from "../components/Constants";
const BASE_URL = `${API_URL}RBAC`;

const RBAC = {
    userPermission: async () => {
        try {
            const token = getToken(); // Fetch token dynamically
            const response = await fetch(BASE_URL, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'api-key': apiKey
                }
            });


            if (!response.ok) {
                if (response.status === 500) {
                  sessionStorage.clear();
                  window.location.href = '/login';
                  return { error: true };
                }
        
                const errorData = await response.json();
                if (response.status === 429) {
                  return { status: 429, message: 'Too many requests. Please wait.' };
                }
        
                const errorMessage = errorData.message || 'An unexpected error occurred.';
                throw new Error(errorMessage);
              }

            const data = await response.json();

            return Array.isArray(data) ? data : [];

        } catch (error) {
            return { error: true };
        }
    },
};

export default RBAC;
