

import { API_URL, getToken, apiKey } from "../components/Constants";
const BASE_URL = `${API_URL}personalTitle`;

const PersonalTitleService = {

  getAllPersonals: async () => {

    try {
      const token = getToken();
      const response = await fetch(BASE_URL, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'api-key': apiKey
        }
      });

      if (!response.ok) {
        const errorData = await response.json();
        if (response.status === 422) {
          throw new Error(errorData.join(', ')); // Join messages into a single string

        }
        const errorMessage = errorData.message || 'An unexpected error occurred.';
        throw new Error(errorMessage);
      }
      const data = await response.json();
      return data.personals;
    } catch (error) {
      throw error;
    }
  },

  getPersonalsByUuid: async (uuid) => {
    try {
      const token = getToken();
      const response = await fetch(`${BASE_URL}/${uuid}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'api-key': apiKey
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        if (response.status === 422) {
          throw new Error(errorData.join(', ')); // Join messages into a single string

        }
        const errorMessage = errorData.message || 'An unexpected error occurred.';
        throw new Error(errorMessage);
      }

      const data = await response.json();
      return data.personal;

    } catch (error) {
      throw error;
    }
  },

  createPersonal: async (postData) => {

    try {
      const token = getToken();
      const response = await fetch(`${BASE_URL}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${token}`,
          'api-key': apiKey
        },
        body: JSON.stringify(postData),
      });

      if (!response.ok) {
        const errorData = await response.json();
        if (response.status === 422) {
          throw new Error(errorData.join(', ')); // Join messages into a single string
        }
        const errorMessage = errorData.message || 'An unexpected error occurred.';
        throw new Error(errorMessage);
      }
      const data = await response.json();
      return data;

    } catch (error) {
      throw error;
    }
  },

  updatePersonals: async (uuid, updatedData) => {
    try {
      const token = getToken();
      const response = await fetch(`${BASE_URL}/${uuid}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${token}`,
          'api-key': apiKey
        },
        body: JSON.stringify(updatedData),
      });

      if (!response.ok) {
        const errorData = await response.json();
        if (response.status === 422) {
          throw new Error(errorData.join(', ')); // Join messages into a single string
        }
        const errorMessage = errorData.message || 'An unexpected error occurred.';
        throw new Error(errorMessage);
      }
      const data = await response.json();
      return data;

    } catch (error) {
      throw error;
    }
  },

  deletePersonals: async (uuid) => {
    try {
      const token = getToken();
      const response = await fetch(`${BASE_URL}/${uuid}`, {
        method: 'DELETE',
        headers: {
          // 'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'api-key': apiKey
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        if (response.status === 422) {
          throw new Error(errorData.join(', ')); // Join messages into a single string
        }
        const errorMessage = errorData.message || 'An unexpected error occurred.';
        throw new Error(errorMessage);
      }

      const data = await response.json();
      return data;

    } catch (error) {

      throw error;

    }


  },


};

export default PersonalTitleService;