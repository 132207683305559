import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Table, Button, Modal, Form } from 'react-bootstrap';
import PageHeader from '../../../Layouts/PageHeader/PageHeader';
import PersonalTitleService from '../../../services/PersonalTitleService';
import Rodal from "rodal";
import 'rodal/lib/rodal.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Link } from 'react-router-dom';
import usePermissions from '../../../hooks/usePermissions';

const PersonalTitleList = () => {
  const { hasPermission } = usePermissions(); // Use the custom hook
  const [personals, setPersonals] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [selectedPersonalList, setSelectedBudgetList] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [visible, setVisible] = useState(false);
  const [newItemData, setNewItemData] = useState({
    name: '',
  });




  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await PersonalTitleService.getAllPersonals();
        setPersonals(data);
      } catch (error) {
        toast.error(error);
      }
    };

    fetchData();
  }, []);


  //new 

  // Handle delete action
  const handleDelete = (uuid) => {
    setSelectedBudgetList(uuid);
    setVisible(true);
  };

  // Confirm delete
  const confirmDelete = async () => {
    try {
      await PersonalTitleService.deletePersonals(selectedPersonalList);
      setPersonals(personals.filter(row => row.uuid !== selectedPersonalList)); // Filter the deleted item
      setVisible(false);
      toast.success('Successfully Deleted');
    } catch (error) {
      toast.error(error);
    }
  };

  // Close Modal
  const handleModalClose = () => {
    setShowModal(false);
    setIsEdit(false); // Reset edit mode
    setNewItemData({
      name: '',
    });
  };

  // Show Modal for add/edit
  const handleModalShow = () => {
    setShowModal(true);
  };

  //Edit budget item
  const handleEdit = (personal) => {
    setSelectedBudgetList(personal.uuid);
    setNewItemData({
      ...personal,  // Populate the form with the existing budget data
      name: personal.name, // Ensure you map correctly
    });
    setIsEdit(true); // Set edit mode to true
    setShowModal(true);
  };

  // Handle form input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewItemData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle form submit (Add or Edit)
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (isEdit) {
        try {
          await PersonalTitleService.updatePersonals(selectedPersonalList, newItemData);
          setPersonals(personals.map(b => (b.uuid === selectedPersonalList ? { ...b, ...newItemData } : b)));

          toast.success('Successfully updated');
        } catch (error) {
          toast.error(error.message);
        }

      } else {
        try {
          const newItem = await PersonalTitleService.createPersonal(newItemData);
          setPersonals([...personals, newItem.personals]);
          toast.success('Successfully added');
        } catch (error) {
          toast.error(error.message);
        }

      }
    } catch (error) {
      toast.error(error.message);
    }

    handleModalClose();
  };

  return (
    <div >
      <ToastContainer />
      <PageHeader titles="All Personal Title" active="List" items={['Personal Title']} />

      {/* <!-- Row --> */}
      <Row>
        <Col xl={12}>
          <Card>
            <Card.Header className="bg-success text-white d-flex justify-content-between align-items-center">
              <Card.Title as='h3'>Personal Titles</Card.Title>
              {hasPermission('personalTitle-create') && (
                <Button variant="light" onClick={handleModalShow}>New Personal Title</Button>
              )}
            </Card.Header>

            <Card.Body>
              {/* <p>Using the most basic table markup, here’s how <code className="highlighter-rouge">.table</code> based tables look in Bootstrap.</p> */}
              <div className="table-responsive">
                <Table className="text-nowrap text-md-nowrap mb-0">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Day</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {personals.length > 0 ? (
                      personals.map((row, index) => (
                        <tr key={row.uuid}>
                          <td>{index + 1}</td>
                          <td>{row.name}</td>
                          <td >
                            {hasPermission('personalTitle-delete') && (
                              <Link
                                onClick={() => handleDelete(row.uuid)}
                                className="text-danger me-3 action-link"
                              >
                                <i className="ion ion-trash-a"></i> Delete
                              </Link>
                            )}

                            {hasPermission('personalTitle-edit') && (
                              <Link
                                onClick={() => handleEdit(row)}
                                className="text-info action-link"
                              >
                                <i className="ion ion-edit"></i> Edit
                              </Link>
                            )}

                          </td>

                        </tr>
                      ))
                    ) : (
                      ''
                    )}



                  </tbody>
                </Table>

              </div>
            </Card.Body>
          </Card>
        </Col>

      </Row>
      {/* <!-- End Row --> */}

      <Modal show={showModal} onHide={handleModalClose} centered>
        <Modal.Header closeButton className="bg-success text-white">
          <Modal.Title className="w-100 text-center">{isEdit ? 'Edit ' : 'Add '}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>

            <Form.Group controlId="formTitle" className="mb-3">
              <Form.Label>Title </Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={newItemData.name}
                onChange={handleInputChange}
                placeholder="number"
                required
              />
            </Form.Group>







            <div className="d-flex justify-content-end">
              <Button variant="secondary" onClick={handleModalClose}>Close</Button>
              <Button variant="success" type="submit" className="px-4">
                {isEdit ? 'Update ' : 'Save Changes'}
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      <Rodal visible={visible} onClose={() => setVisible(false)} animation='zoom' height={170}>
        <div>
          <h4>Confirm Delete</h4>
          <p>Are you sure you want to delete this Price?</p>
          <Button variant="danger" className="me-2" onClick={confirmDelete}>Delete</Button>
          <Button variant="default" onClick={() => setVisible(false)}>Cancel</Button>
        </div>
      </Rodal>
    </div>
  )
};

export default PersonalTitleList;