import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import RBAC from '../services/RBAC';

const useRBAC = (requiredPermission, redirectPath) => {
    const [userPermissions, setUserPermissions] = useState([]);
    const [loading, setLoading] = useState(true); // Add loading state
    const navigate = useNavigate();

    useEffect(() => {
        const fetchPermissions = async () => {
            setLoading(true); // Set loading before fetching
            const permissions = await RBAC.userPermission();
            setUserPermissions(permissions);
            setLoading(false); // Set loading to false after fetching
        };

        fetchPermissions();
    }, []); // Empty dependency array ensures this runs only once

    useEffect(() => {
        if (!loading) { // Only check permissions if not loading
            if (!userPermissions.includes(requiredPermission)) {
                console.log(`User does not have ${requiredPermission} permission.`);
                navigate(redirectPath, { state: { noPermission: true } });
            } else {
                console.log(`User has ${requiredPermission} permission.`);
            }
        }
    }, [userPermissions, loading, navigate, requiredPermission, redirectPath]);

    return userPermissions; // Return permissions if needed in the component
};

export default useRBAC;
