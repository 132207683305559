// authUtils.js

export const checkToken = () => {
    const token = localStorage.getItem('token');
    if (!token) {
//console.log('Token ' + token);
     // window.location.href = '/';
    }
  };
  
   