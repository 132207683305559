import React, { Fragment, lazy } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ProtectedRoute from './components/ProtectedRoute';
import Loader from './Layouts/Loader/Loader';
import "./index.scss";
import reportWebVitals from './reportWebVitals';
import PersonalTitleList from './components/pages/PersonalTitle/PersonalTitleList';
import PersonalTitleCreate from './components/pages/PersonalTitle/PersonalTitleCreate';
import PersonalTitleEdit from './components/pages/PersonalTitle/PersonalTitleEdit';
import ServicesList from './components/pages/Services/ServicesList';
import ServicesCreate from './components/pages/Services/ServicesCreate';
import ServicesEdit from './components/pages/Services/ServicesEdit';

// import RoleUsersList from './components/pages/Roles/RoleUsersList';




// import PrivateRoute from './components/PrivateRoute';



const App = lazy(() => import('./components/app'));

const MySenderIdList = lazy(() => import('./components/pages/MySenderId/MySenderIdList'));
const VendorList = lazy(() => import('./components/pages/Vendors/VendorList'));

const UnauthorizedPage = lazy(() => import('./components/UnauthorizedPage'));

const PermissionList = lazy(() => import('./components/pages/Permission/PermissionList'));
const PermissionCreate = lazy(() => import('./components/pages/Permission/PermissionCreate'));
const PermissionEdit = lazy(() => import('./components/pages/Permission/PermissionEdit'));

const UsersList = lazy(() => import('./components/pages/Users/UsersList'));
const UsersCreate = lazy(() => import('./components/pages/Users/UsersCreate'));
const UsersEdit = lazy(() => import('./components/pages/Users/UsersEdit'));

const RoleEdit = lazy(() => import('./components/pages/Roles/RoleEdit'));
const RoleList = lazy(() => import('./components/pages/Roles/RoleList'));
const RoleCreate = lazy(() => import('./components/pages/Roles/RoleCreate'));
const RoleUsersList = lazy(() => import('./components/pages/Roles/RoleUsersList'));


const WeekEdit = lazy(() => import('./components/pages/Week/WeekEdit'));
const WeekList = lazy(() => import('./components/pages/Week/WeekList'));
const WeekCreate = lazy(() => import('./components/pages/Week/WeekCreate'));

const MonthEdit = lazy(() => import('./components/pages/Month/MonthEdit'));
const MonthList = lazy(() => import('./components/pages/Month/MonthList'));
const MonthCreate = lazy(() => import('./components/pages/Month/MonthCreate'));

const SendTimeEdit = lazy(() => import('./components/pages/SendTimes/SendTimeEdit'));
const SendTimeList = lazy(() => import('./components/pages/SendTimes/SendTimeList'));
const SendTimeCreate = lazy(() => import('./components/pages/SendTimes/SendTimeCreate'));

const SenderidEdit = lazy(() => import('./components/pages/Senderid/SenderidEdit'));
const SenderidList = lazy(() => import('./components/pages/Senderid/SenderidList'));
const SenderidCreate = lazy(() => import('./components/pages/Senderid/SenderidCreate'));
const SenderidAssign = lazy(() => import('./components/pages/Senderid/SenderidAssign'));

const AccountTypeEdit = lazy(() => import('./components/pages/AccountType/AccountTypeEdit'));
const AccountTypeList = lazy(() => import('./components/pages/AccountType/AccountTypeList'));
const AccountTypeCreate = lazy(() => import('./components/pages/AccountType/AccountTypeCreate'));

//const AccountTypeEdit = lazy(() => import('./components/pages/AccountType/AccountTypeEdit'));
const AdsList = lazy(() => import('./components/pages/ads/AdsList'));
const AdsCreate = lazy(() => import('./components/pages/ads/AdsCreate'));
const AdsEdit = lazy(() => import('./components/pages/ads/AdsEdit'));
//const AccountTypeCreate = lazy(() => import('./components/pages/AccountType/AccountTypeCreate'));

const MyDashboardEdit = lazy(() => import('./components/pages/MyDashboard/MyDashboardEdit'));
const MyDashboardList = lazy(() => import('./components/pages/MyDashboard/MyDashboardList'));
const MyDashboardCreate = lazy(() => import('./components/pages/MyDashboard/MyDashboardCreate'));

const ForgotPassword = lazy(() => import('./components/Authentication/Forgot Password/ForgotPassword'));
const LockScreen = lazy(() => import('./components/Authentication/LockScreen/LockScreen'));
const Register = lazy(() => import('./components/Authentication/Register/Register'));

const Dashboard = lazy(() => import('./components/Dashboard/Dashboard'));
const MessageInbox = lazy(() => import('./components/pages/Messages/MessageInbox'));
const MessageCompose = lazy(() => import('./components/pages/Messages/MessageCompose'));
const MessageRead = lazy(() => import('./components/pages/Messages/MessageRead'));
const MessageEdit = lazy(() => import('./components/pages/Messages/MessageEdit'));
const MessageReports = lazy(() => import('./components/pages/Messages/MessageReports'));

const ContactAddress = lazy(() => import('./components/pages/PhoneBook/ContactAddress'));
const ContactAddressCreate = lazy(() => import('./components/pages/PhoneBook/ContactAddressCreate'));
const ContactAddressEdit = lazy(() => import('./components/pages/PhoneBook/ContactAddressEdit'));
const GroupMembersList = lazy(() => import('./components/pages/PhoneBook/GroupMembersList'));

const LiveEventList = lazy(() => import('./components/pages/LiveEvents/LiveEventList'));
const LiveEventEdit = lazy(() => import('./components/pages/LiveEvents/LiveEventEdit'));
const LiveEventCreate = lazy(() => import('./components/pages/LiveEvents/LiveEventCreate'));


const MyEventsList = lazy(() => import('./components/pages/MyEvents/MyEventsList'));


const PurposeList = lazy(() => import('./components/pages/Purpose/PurposeList'));
const PurposeEdit = lazy(() => import('./components/pages/Purpose/PurposeEdit'));
const PurposeCreate = lazy(() => import('./components/pages/Purpose/PurposeCreate'));

const KadiTemplatesList = lazy(() => import('./components/pages/kadiTemplate/KadiTemplateList'));
const KadiTemplatesEdit = lazy(() => import('./components/pages/kadiTemplate/KadiTemplatesEdit'));
const KadiTemplatesCreate = lazy(() => import('./components/pages/kadiTemplate/KadiTemplatesCreate'));

const MyKadiEdit = lazy(() => import('./components/pages/AccountKadi/MyKadiEdit'));
const AllAccountsList = lazy(() => import('./components/pages/AllAccounts/AllAccountsList'));
const AccountMembersList = lazy(() => import('./components/pages/AllAccounts/AccountMembersList'));
const AccountsBalanceList = lazy(() => import('./components/pages/AllAccounts/AccountsBalanceList'));
const AccountBalanceCreate = lazy(() => import('./components/pages/AllAccounts/AccountBalanceCreate'));
const AccountBalanceEdit = lazy(() => import('./components/pages/AllAccounts/AccountBalanceEdit'));
const AccountsWithoutBalanceList = lazy(() => import('./components/pages/AllAccounts/AccountsWithoutBalanceList'));
const AllKadiAccountsList = lazy(() => import('./components/pages/AllAccounts/AllKadiAccountsList'));
const AccountProfileList = lazy(() => import('./components/pages/AccountProfile/AccountProfileList'));


const TodaySmsList = lazy(() => import('./components/SmsConfig/TodaySms/TodaySmsList'));
const AccountByMessagesList = lazy(() => import('./components/pages/AllAccounts/AccountByMessagesList'));

const ClientExcelList = lazy(() => import('./components/pages/ExcelFiles/ClientExcelList'));
const AdminExcelList = lazy(() => import('./components/pages/ExcelFiles/AdminExcelList'));
const AdminExcelComment = lazy(() => import('./components/pages/ExcelFiles/AdminExcelComment'));

const PhotoList = lazy(() => import('./components/pages/Photos/PhotoList'));
const BudgetList = lazy(() => import('./components/pages/Budgets/BudgetList'));
const SmsPackageList = lazy(() => import('./components/pages/SmsPackages/SmsPackageList'));
const AppVersionsList = lazy(() => import('./components/pages/AppVersions/AppVersionsList'));
const PaymentDetailsList = lazy(() => import('./components/pages/PaymentDetails/PaymentDetailsList'));

const FaqList = lazy(() => import('./components/pages/Faq/FaqList'));
const DefaultSettingUpdate = lazy(() => import('./components/pages/DefaultSettings/DefaultSettingUpdate'));

const ChatList = lazy(() => import('./components/pages/Chat/Chat'));
const BlogDetails = lazy(() => import('./components/pages/Blogs/BlogDetails'));

const UserChangePassword = lazy(() => import('./components/pages/ChangePassword/UserChangePassword'));

const PolicyList = lazy(() => import('./components/pages/Privancy/PolicyList'));



const Error500 = lazy(() => import('./components/Authentication/errorPage/Error500/Error500'));
const Login = lazy(() => import('./components/Authentication/Login/Login'));
const AuthenticationPage = lazy(() => import('./components/AuthenticationPage'));
const ErrorPages = lazy(() => import('./components/ErrorPages'));
const Switcherapp = lazy(() => import("./components/Switcherapp"))
const Landing = lazy(() => import("./components/Landing"))

// const LandingPage = lazy(() => import("./components/LandingPage/LandingPage"))


const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  // <React.StrictMode>
  <Fragment>
    <BrowserRouter>
      <React.Suspense fallback={<Loader />}>
        <Routes>

          <Route path="/" element={<AuthenticationPage />}>
            {/* <Route  element={<Login />} /> */}
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/forgotpassword" element={<ForgotPassword />} />
            <Route path="/privacy-policy" element={<PolicyList />} />,
          </Route>

          <Route >
            <Route index element={<Landing />} />
          </Route>

          <Route path="/" element={<App />}>

            <Route
              path="/dashboard"
              element={<ProtectedRoute element={Dashboard} permission="admin-dashboard" />}
            />



            <Route path="/permission" element={<ProtectedRoute element={PermissionList} permission="permission-list" role="Super Admin" />} />
            <Route path='/permission/create' element={< ProtectedRoute element={PermissionCreate} permission="permission-create" role="Super Admin" />} />
            <Route path='/permission/edit/:uuid' element={<ProtectedRoute element={PermissionEdit} permission="permission-edit" role="Super Admin" />} />

            <Route path="/users" element={<ProtectedRoute element={UsersList} permission="user-list" role="Super Admin" />} />
            <Route path="/users/create" element={<ProtectedRoute element={UsersCreate} permission="user-create" role="Super Admin" />} />
            <Route path="/users/edit/:uuid" element={<ProtectedRoute element={UsersEdit} permission="user-edit" role="Super Admin" />} />

            <Route path="/roles" element={<ProtectedRoute element={RoleList} permission="role-list" role="Super Admin" />} />
            <Route path="/roles/create" element={<ProtectedRoute element={RoleCreate} permission="role-create" role="Super Admin" />} />
            <Route path="/roles/edit/:uuid" element={<ProtectedRoute element={RoleEdit} permission="role-edit" role="Super Admin" />} />
            <Route path="/roles/:uuid" element={<ProtectedRoute element={RoleUsersList} permission="role-list" role="Super Admin" />} />

            <Route path="/week" element={<ProtectedRoute element={WeekList} permission="week-list" />} />
            <Route path="/week/create" element={<ProtectedRoute element={WeekCreate} permission="week-create" />} />
            <Route path="/week/edit/:uuid" element={<ProtectedRoute element={WeekEdit} permission="week-edit" />} />

            <Route path="/month" element={<ProtectedRoute element={MonthList} permission="month-list" />} />
            <Route path="/month/create" element={<ProtectedRoute element={MonthCreate} permission="month-create" />} />
            <Route path="/month/edit/:uuid" element={<ProtectedRoute element={MonthEdit} permission="month-edit" />} />

            <Route path="/sendtimes" element={<ProtectedRoute element={SendTimeList} permission="sendtime-list" />} />
            <Route path="/sendtimes/create" element={<ProtectedRoute element={SendTimeCreate} permission="sendtime-create" />} />
            <Route path="/sendtimes/edit/:uuid" element={<ProtectedRoute element={SendTimeEdit} permission="sendtime-edit" />} />

            <Route path="/senderid" element={<ProtectedRoute element={SenderidList} permission="senderid-list" />} />
            <Route path="/senderid/create" element={<ProtectedRoute element={SenderidCreate} permission="senderid-create" />} />
            <Route path="/senderid/edit/:uuid" element={<ProtectedRoute element={SenderidEdit} permission="senderid-edit" />} />

            <Route path="/assign_senderid" element={<ProtectedRoute element={SenderidAssign} permission="usersenderid-list" />} />

            <Route path="/personalTitle" element={<ProtectedRoute element={PersonalTitleList} permission="personalTitle-list" />} />
            <Route path="/personalTitle/create" element={<ProtectedRoute element={PersonalTitleCreate} permission="personalTitle-create" />} />
            <Route path="/personalTitle/edit/:uuid" element={<ProtectedRoute element={PersonalTitleEdit} permission="personalTitle-edit" />} />

            <Route path="/accountType" element={<ProtectedRoute element={AccountTypeList} permission="accountType-list" />} />
            <Route path="/accountType/create" element={<ProtectedRoute element={AccountTypeCreate} permission="accountType-create" />} />
            <Route path="/accountType/edit/:uuid" element={<ProtectedRoute element={AccountTypeEdit} permission="accountType-edit" />} />

            <Route path="/appServices" element={<ProtectedRoute element={ServicesList} permission="appservice-list" />} />
            <Route path="/appServices/create" element={<ProtectedRoute element={ServicesCreate} permission="appservice-create" />} />
            <Route path="/appServices/edit/:uuid" element={<ProtectedRoute element={ServicesEdit} permission="appservice-edit" />} />

            <Route path="/mydashboard" element={<ProtectedRoute element={MyDashboardList} permission="account-list" />} />
            <Route path="/mydashboard/create" element={<ProtectedRoute element={MyDashboardCreate} permission="account-create" />} />
            <Route path="/mydashboard/edit/:uuid" element={<ProtectedRoute element={MyDashboardEdit} permission="account-edit" />} />

            {/* user routes  */}
            <Route path="/mySenderId" element={<ProtectedRoute element={MySenderIdList} permission="usersenderid-list" />} />
            <Route path='/vendorService/:uuid' element={<ProtectedRoute element={VendorList} permission="userService-list" />} />
            <Route path='/messageInbox/:uuid' element={<ProtectedRoute element={MessageInbox} permission="message-list" />} />
            <Route path='/messageCompose/:uuid' element={<ProtectedRoute element={MessageCompose} permission="message-create" />} />
            <Route path='/messageRead/:uuid' element={<ProtectedRoute element={MessageRead} permission="message-edit" />} />
            <Route path='/messageEdit/:uuid' element={<ProtectedRoute element={MessageEdit} permission="message-edit" />} />
            <Route path='/messageReports/:uuid' element={<ProtectedRoute element={MessageReports} permission="message-reports" />} />


            <Route path='/contact-address/:uuid' element={<ProtectedRoute element={ContactAddress} permission="member-list" />} />
            <Route path='/contact-address/:uuid/create' element={<ProtectedRoute element={ContactAddressCreate} permission="member-create" />} />
            <Route path='/contact-address/edit/:uuid' element={<ProtectedRoute element={ContactAddressEdit} permission="member-edit" />} />
            <Route path='/contact-group/:uuid' element={<ProtectedRoute element={GroupMembersList} permission="groupMember-list" />} />

            <Route path="/liveevents" element={<ProtectedRoute element={LiveEventList} permission="liveEvent-list" />} />,
            <Route path="/liveevents/create" element={<ProtectedRoute element={LiveEventCreate} permission="liveEvent-create" />} />
            <Route path="/liveevents/edit/:uuid" element={<ProtectedRoute element={LiveEventEdit} permission="liveEvent-edit" />} />

            <Route path="/advertisement" element={<ProtectedRoute element={AdsList} permission="ads-list" />} />
            <Route path="/advertisement/create" element={<ProtectedRoute element={AdsCreate} permission="ads-create" role="Super Admin" />} />
            <Route path="/advertisement/edit/:uuid" element={<ProtectedRoute element={AdsEdit} permission="ads-edit" />} />

            <Route path="/purpose" element={<ProtectedRoute element={PurposeList} permission="purpose-list" />} />
            <Route path="/purpose/create" element={<ProtectedRoute element={PurposeCreate} permission="purpose-create" role="Super Admin" />} />
            <Route path="/purpose/edit/:uuid" element={<ProtectedRoute element={PurposeEdit} permission="purpose-edit" />} />

            <Route path="/kadiTemplate" element={<ProtectedRoute element={KadiTemplatesList} permission="kaditemplate-list" />} />
            <Route path="/kadiTemplate/create" element={<ProtectedRoute element={KadiTemplatesCreate} permission="kaditemplate-create" />} />
            <Route path="/kadiTemplate/edit/:uuid" element={<ProtectedRoute element={KadiTemplatesEdit} permission="kaditemplate-edit" />} />

            <Route path="/MyKadi/:uuid" element={<ProtectedRoute element={MyKadiEdit} permission="accountkadi-edit" />} />

            <Route path="/communities" element={<ProtectedRoute element={MyEventsList} permission="community-list" />} />

            <Route path="/all-accounts-list" element={<ProtectedRoute element={AllAccountsList} permission="AllAccounts-list" role="Super Admin" />} />
            <Route path="/all-kadiAccounts-list" element={<ProtectedRoute element={AllKadiAccountsList} permission="AllKadiAccounts-list" role="Super Admin" />} />



            <Route path="/account-members-list/:uuid" element={<ProtectedRoute element={AccountMembersList} permission="account-Member-List" />} />

            <Route path="/accounts-balance" element={<ProtectedRoute element={AccountsBalanceList} permission="balance-list" role="Super Admin" />} />
            <Route path="/accounts-balance-create/:uuid" element={<ProtectedRoute element={AccountBalanceCreate} permission="balance-create" />} />
            <Route path="/accounts-balance-edit/:uuid" element={<ProtectedRoute element={AccountBalanceEdit} permission="balance-edit" role="Super Admin" />} />
            <Route path="/account-by-messages/:uuid" element={<ProtectedRoute element={AccountByMessagesList} permission="account-messages" />} />
            <Route path="/accounts-withoutBalance" element={<ProtectedRoute element={AccountsWithoutBalanceList} permission="accounts-withoutBalance" />} />
            <Route path="/account-profileList/:uuid" element={<ProtectedRoute element={AccountProfileList} permission="account-profileList"  />} />


            {/* <Route path="/account-by-messages/:uuid" element={<AccountByMessagesList />} />, */}
            <Route path="/client-excel-list/:uuid" element={<ProtectedRoute element={ClientExcelList} permission="clientExcel-list" />} />,
            <Route path="/admin-excel-list" element={<ProtectedRoute element={AdminExcelList} permission="admin-excel-list" />} />,
            <Route path="/admin-excel-comment/:uuid" element={<ProtectedRoute element={AdminExcelComment} permission="admin-excel-comment" />} />,

            <Route path="/photo-album/:uuid" element={<PhotoList />} />,

            <Route path="/today-smslist" element={<ProtectedRoute element={TodaySmsList} permission="TodaySms-list" />} />,

            <Route path="/my-budget/:uuid" element={<BudgetList />} />,
            <Route path="/change-password" element={<UserChangePassword />} />,


            <Route path="/app-versionsList" element={<ProtectedRoute element={AppVersionsList} permission="appVersion-list" role="Super Admin" />} />,
            <Route path="/paymentDetails" element={<ProtectedRoute element={PaymentDetailsList} permission="paymentDetail-list" />} />,


            <Route path="/sms-packageList" element={<SmsPackageList />} />,
            <Route path="/faq" element={<FaqList />} />,
            <Route path="/chat" element={<ChatList />} />,
            <Route path="/defaults" element={<DefaultSettingUpdate />} />,
            <Route path="/blog" element={<BlogDetails />} />,


            <Route path='/unauthorized' element={<UnauthorizedPage />} />


            {/* <Route path={`${process.env.PUBLIC_URL}/pages/tables/edittables`} element={<EditTable />} />, */}
          </Route>

          <Route>
            <Route path={`${process.env.PUBLIC_URL}/pages/switcher/switcherstyle1`} element={<Switcherapp />} />,
          </Route>


        </Routes>
      </React.Suspense>
    </BrowserRouter>
  </Fragment>

  // </React.StrictMode>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
